/*global $*/
/*eslint no-undef: "error"*/

import Swiper from 'swiper'
import {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs} from 'swiper/modules'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs])

export default class SwiperSlider {
    createBlock () {
        return new Swiper('#slider--block', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--block-next',
                prevEl: '.slider--block-prev',
            },
            pagination: {
                el: '.swiper-pagination--block',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1.5,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1.5,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 1.75,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 1.75,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 2.25,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }

    createService () {
        return new Swiper('#slider--service', {
            slidesPerView: 20,
            spaceBetween: 3.5,
            // loop: true,
            navigation: {
                nextEl: '.slider--service-next',
                prevEl: '.slider--service-prev',
            },
            pagination: {
                el: '.swiper-pagination--service',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2.25,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 2.25,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 2.25,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 2.5,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 3.5,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 3.5,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3.5,
                    spaceBetween: 20,
                }
            }
        })
    }

    createPrice () {
        return new Swiper('.slider--price', {
            slidesPerView: 20,
            spaceBetween: 2.25,
            // loop: true,
            navigation: {
                nextEl: '.slider--price-next',
                prevEl: '.slider--price-prev',
            },
            pagination: {
                el: '.swiper-pagination--price',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 1.25,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 2.25,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 2.25,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 2.25,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 2.25,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 2.25,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 2.25,
                    spaceBetween: 20,
                }
            }
        })
    }

    createProduct () {
        return new Swiper('.slider--product', {
            slidesPerView: 20,
            spaceBetween: 6,
            // loop: true,
            navigation: {
                nextEl: '.slider--product-next',
                prevEl: '.slider--product-prev',
            },
            pagination: {
                el: '.swiper-pagination--product',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.5,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1.5,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 2.125,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 2.125,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 2.25,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 2.25,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2.5,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 3.25,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 3.5,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 4.5,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                }
            }
        })
    }

    createLook () {
        return new Swiper('.slider--look', {
            slidesPerView: 20,
            spaceBetween: 6,
            // loop: true,
            navigation: {
                nextEl: '.slider--look-next',
                prevEl: '.slider--look-prev',
            },
            pagination: {
                el: '.swiper-pagination--look',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.5,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1.5,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 2.125,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 2.125,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 2.25,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 2.25,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2.5,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 3.25,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 3.5,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 4.5,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                }
            }
        })
    }

    createProductService () {
        return new Swiper('.slider--product-service', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--product-service-next',
                prevEl: '.slider--product-service-prev',
            },
            pagination: {
                el: '.swiper-pagination--product-service',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 1.5,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 2.5,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }

    createHall () {
        return new Swiper('.slider--hall', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--hall-next',
                prevEl: '.slider--hall-prev',
            },
            pagination: {
                el: '.swiper-pagination--hall',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 1.25,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }
    createHallService () {
        return new Swiper('.slider--hall-service', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--hall-service-next',
                prevEl: '.slider--hall-service-prev',
            },
            pagination: {
                el: '.swiper-pagination--hall-service',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 1.25,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }
    createHallItem () {
        return new Swiper('.slider--hall-item', {
            slidesPerView: 1,
            spaceBetween: 5,
            loop: true,
            navigation: {
                nextEl: '.slider--hall-item-next',
                prevEl: '.slider--hall-item-prev',
            },
            pagination: {
                el: '.swiper-pagination--hall-item',
                clickable: true
            },
            breakpoints: {}
        })
    }
    createProp () {
        return new Swiper('.slider--prop', {
            slidesPerView: 20,
            spaceBetween: 4,
            // loop: true,
            navigation: {
                nextEl: '.slider--prop-next',
                prevEl: '.slider--prop-prev',
            },
            pagination: {
                el: '.swiper-pagination--prop',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1.25,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 2.25,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 2.25,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                }
            }
        })
    }

    createReview () {
        return new Swiper('.slider--review', {
            slidesPerView: 20,
            spaceBetween: 2,
            // loop: true,
            navigation: {
                nextEl: '.slider--review-next',
                prevEl: '.slider--review-prev',
            },
            pagination: {
                el: '.swiper-pagination--review',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1.25,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 1.5,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                }
            }
        })
    }

    createGallery () {
        return new Swiper('.slider--gallery', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--gallery-next',
                prevEl: '.slider--gallery-prev',
            },
            pagination: {
                el: '.swiper-pagination--gallery',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1.125,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1.25,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 2.25,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 2.25,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }

    createGalleryProduct () {
        return new Swiper('.slider--gallery-product', {
            slidesPerView: 20,
            spaceBetween: 1,
            // loop: true,
            navigation: {
                nextEl: '.slider--gallery-product-next',
                prevEl: '.slider--gallery-product-prev',
            },
            pagination: {
                el: '.swiper-pagination--gallery-product',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 12
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                }
            }
        })
    }

    createDoc () {
        return new Swiper('.slider--doc', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--doc-next',
                prevEl: '.slider--doc-prev',
            },
            pagination: {
                el: '.swiper-pagination--doc',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.5,
                    spaceBetween: 12,
                },
                320: {
                    slidesPerView: 1.5,
                    spaceBetween: 12,
                },
                360: {
                    slidesPerView: 1.5,
                    spaceBetween: 12,
                },
                375: {
                    slidesPerView: 1.5,
                    spaceBetween: 12,
                },
                414: {
                    slidesPerView: 2.25,
                    spaceBetween: 12,
                },
                480: {
                    slidesPerView: 2.25,
                    spaceBetween: 12,
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 12
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }
    createSection () {
        return new Swiper('.slider--section', {
            slidesPerView: 20,
            spaceBetween: 7,
            // loop: true,
            navigation: {
                nextEl: '.slider--section-next',
                prevEl: '.slider--section-prev',
            },
            pagination: {
                el: '.swiper-pagination--section',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2.25,
                    spaceBetween: 12,
                },
                320: {
                    slidesPerView: 2.25,
                    spaceBetween: 12,
                },
                360: {
                    slidesPerView: 2.25,
                    spaceBetween: 12,
                },
                375: {
                    slidesPerView: 2.25,
                    spaceBetween: 12,
                },
                414: {
                    slidesPerView: 2.5,
                    spaceBetween: 12,
                },
                480: {
                    slidesPerView: 3.25,
                    spaceBetween: 12,
                },
                514: {
                    slidesPerView: 3.5,
                    spaceBetween: 12
                },
                640: {
                    slidesPerView: 4.25,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 4.5,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 5.25,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 5.5,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 6.25,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 7,
                    spaceBetween: 20,
                }
            }
        })
    }

    constructor () {
        if (parseInt($('#slider--block').length) > 0) {
            this.createBlock()
        }

        if (parseInt($('#slider--service').length) > 0) {
            this.createService()
        }

        if (parseInt($('.slider--price').length) > 0) {
            this.createPrice()
        }

        if (parseInt($('.slider--product').length) > 0) {
            this.createProduct()
        }

        if (parseInt($('.slider--look').length) > 0) {
            this.createLook()
        }

        if (parseInt($('.slider--product-service').length) > 0) {
            this.createProductService()
        }

        if (parseInt($('.slider--hall').length) > 0) {
            this.createHall()
        }

        if (parseInt($('.slider--hall-service').length) > 0) {
            this.createHallService()
        }

        if (parseInt($('.slider--hall-item').length) > 0) {
            this.createHallItem()
        }

        if (parseInt($('.slider--prop').length) > 0) {
            this.createProp()
        }
        if (parseInt($('.slider--review').length) > 0) {
            this.createReview()
        }

        if (parseInt($('.slider--gallery').length) > 0) {
            this.createGallery()
        }

        if (parseInt($('.slider--gallery-product').length) > 0) {
            this.createGalleryProduct()
        }

        if (parseInt($('.slider--doc').length) > 0) {
            this.createDoc()
        }

        if (parseInt($('.slider--section').length) > 0) {
            this.createSection()
        }
    }
}
